// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ProposalGeneration2 from "../../blocks/ProposalGeneration2/src/ProposalGeneration2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import LeadManagement from "../../blocks/LeadManagement/src/LeadManagement";
import CarouselDisplay from "../../blocks/CarouselDisplay/src/CarouselDisplay";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ImageThumbnail from "../../blocks/ImageThumbnail/src/ImageThumbnail";
import Videos4 from "../../blocks/Videos4/src/Videos4";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Customform from "../../blocks/customform/src/Customform";
import Analytics from "../../blocks/analytics/src/Analytics";
import MerchantDashboard2 from "../../blocks/MerchantDashboard2/src/MerchantDashboard2";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import EventRegistration from "../../blocks/EventRegistration/src/EventRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import PortfolioManagement from "../../blocks/PortfolioManagement/src/PortfolioManagement";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import ReviewPrompt from "../../blocks/ReviewPrompt/src/ReviewPrompt";
import Payments from "../../blocks/Payments/src/Payments";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";



const routeMap = {
ProposalGeneration2:{
 component:ProposalGeneration2,
path:"/ProposalGeneration2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
LeadManagement:{
 component:LeadManagement,
path:"/LeadManagement"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
ImageThumbnail:{
 component:ImageThumbnail,
path:"/ImageThumbnail"},
Videos4:{
 component:Videos4,
path:"/Videos4"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Customform:{
 component:Customform,
path:"/Customform"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
MerchantDashboard2:{
 component:MerchantDashboard2,
path:"/MerchantDashboard2"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
EventRegistration:{
 component:EventRegistration,
path:"/EventRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
PortfolioManagement:{
 component:PortfolioManagement,
path:"/PortfolioManagement"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
ReviewPrompt:{
 component:ReviewPrompt,
path:"/ReviewPrompt"},
Payments:{
 component:Payments,
path:"/Payments"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;